import { React } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// import { Header } from '../Header/Header';
import { OtherPageFooter } from '../Footer/OtherPageFooter';
const PrivateLayout = (props) => {
  const { t } = useTranslation();

  return (
    <div {...props} className="common-container">
      {/* <Header t={t} /><section className="main-section">{props.children}</section>  */}
      <OtherPageFooter t={t} />
    </div>
  );
};

PrivateLayout.propTypes = {
  children: PropTypes.any,
  active: PropTypes.string,
};

export { PrivateLayout };
