import { createSlice } from '@reduxjs/toolkit';

/**
 * Initial Values of globally declared states in redux
 */
const intialState = {
  setting_get: true,
  home_page_header_logo: '',
  home_page_favicon_icon: '',
  home_page_seo_title: '',
  home_page_seo_description: '',
};

/**
 * Slice for Setting Data
 */
export const settingSlice = createSlice({
  name: 'setting',
  initialState: intialState,
  /**
   * Reducer functions for settings info
   */
  reducers: {
    addSetting: (state, { payload }) => {
      state.home_page_header_logo = payload.home_page_header_logo;
      state.home_page_favicon_icon = payload.home_page_favicon_icon;
      state.home_page_seo_title = payload.home_page_seo_title;
      state.home_page_seo_description = payload.home_page_seo_description;
      state.setting_get = payload.setting_get;

      return state;
    },
    clearSetting: () => intialState,
  },
});
export const { addSetting, clearSetting } = settingSlice.actions;
export const settingData = (state) => state.setting;
export default settingSlice.reducer;
