import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';

const OtherPageFooter = () => {
  return (
    <div className="other-footer-container">
      <Container fluid className="footer-container other-footer">
        <img alt="footer-img" className="other-footer-img" />
        <div className="other-footer-content">
          <div className="other-footer-content-details container">
            <div className="copy-right-section copy-right"></div>
            <div className="scroll-top links"></div>
          </div>
        </div>
      </Container>
    </div>
  );
};
OtherPageFooter.propTypes = {
  t: PropTypes.func,
};
export { OtherPageFooter };
