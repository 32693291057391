import { useQuery } from 'react-query';

import { HomeService } from 'api';
import { toast } from 'react-toastify';
const onDefaultError = (error) => {
  toast.error(error.message);
};

/**
 * Hooks for Home page data
 * */
const useGetHomeData = (onSuccess, onError = onDefaultError) => {
  return useQuery(['home-view'], HomeService.getHomeData, {
    onSuccess,
    onError,
  });
};

export { useGetHomeData };
