import React from 'react';
import { Outlet } from 'react-router-dom';

import 'assets/scss/styles.scss';
import { PublicLayout } from 'common/layout';

const PublicRoute = () => {
  return (
    <PublicLayout>
      <Outlet />
    </PublicLayout>
  );
};

export default PublicRoute;
