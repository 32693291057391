import React from 'react';
import { Outlet } from 'react-router-dom';
import 'assets/scss/styles.scss';
import { PrivateLayout } from 'common/layout';

const PrivateRoute = () => {
  /**
   * using selector hooks from redux to get data
   */

  return (
    <PrivateLayout>
      <Outlet />
    </PrivateLayout>
  );
};

export default PrivateRoute;
