import React from 'react';
import Errorpage from 'assets/images/404.png';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { TNButton } from './TNButton';
import 'assets/scss/page/_notfound.scss';
const PageNotFound = (props) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/');
  };
  return (
    <>
      <Container>
        <div className="page-not-found">
          <img src={Errorpage} alt="404" className="not-found-image m-4" />
          <h1 className="page-heading-center">{props.t('front.not_found_oops_text')}</h1>
          <div className="error-page-text">{props.t('front.not_found_text')}</div>
          <div className="primary-button mt-3">
            <TNButton onClick={handleClick} loading={false}>
              {props.t('front.back_to_home')}
            </TNButton>
          </div>
        </div>
      </Container>
    </>
  );
};

PageNotFound.propTypes = {
  from: PropTypes.string,
  t: PropTypes.func,
};

export default PageNotFound;
