import PropTypes from 'prop-types';
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addSetting, settingData } from 'store/features/settingSlice';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { useGetHomeData } from 'hooks';
// import { PageNotFound } from '../common/Components';

import { Helmet } from 'react-helmet';
import { PageNotFound } from 'common/Components';
// import { Header } from 'common/layout/Header/Header';

//  home page route with lazy
const HomePage = React.lazy(() => import('pages/Home/HomePage'));

const PagesRoutes = ({ t }) => {
  const dispatch = useDispatch();

  /**
   * !This API will call when page set. When response come we are Set General Setting Datas
   */
  const { isLoading, data: general_data } = useGetHomeData();
  if (!isLoading) {
    const dataStore = {
      home_page_header_logo: general_data.data?.setting_data.home_page_header_logo,
      home_page_favicon_icon: general_data.data?.setting_data.home_page_favicon_icon,
      home_page_seo_title: general_data.data?.setting_data.home_page_seo_title,
      home_page_seo_description: general_data.data?.setting_data.home_page_seo_description,
      setting_get: true,
    };
    dispatch(addSetting(dataStore));
  }
  /**
   * Getting data from Redux store
   */
  const getSettingData = useSelector(settingData);
  return (
    <Router>
      <Helmet>
        <title>AOT Delivery Home</title>
        <meta name="og:title" content={getSettingData.home_page_seo_title} />
        <meta name="title" content={getSettingData.home_page_seo_title} />
        <meta name="description" content={getSettingData.home_page_seo_description} />
        <meta name="og:description" content={getSettingData.home_page_seo_description} />
        <link rel="icon" href={`${getSettingData.home_page_favicon_icon}`} />
      </Helmet>
      <Routes>
        {/* Public Routes for Home Page */}

        <Route path="/" element={<HomePage t={t} />} />
        <Route element={<PublicRoute />}>
          {/* <Route path="/*" element={<PageNotFound t={t} />} from="front" /> */}
        </Route>
        <Route element={<PublicRoute />}>
          {/* <Route path="/" element={<Header t={t} />} from="front" /> */}
        </Route>

        {/* Private Routes for organisation */}
        <Route element={<PrivateRoute />}></Route>
        <Route path="/404" element={<PageNotFound t={t} />} from="front" />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </Router>
  );
};
PagesRoutes.propTypes = {
  t: PropTypes.func,
};
export { PagesRoutes };
